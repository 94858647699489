
import { Watch, Prop, Mixins, Component, Ref, InjectReactive } from "vue-property-decorator";

import ETable from "@/components/ETable.vue";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { userMemberRecuperateList, deviceClassTypeList, getCommentsByPms } from "@/api/userDetection";
import dayjs from "dayjs";

import CommentDetailDialog from "./components/commentDetailDialog.vue";

@Component({
    components: { ETable, CommentDetailDialog }
})
export default class UserRecuperateRecord extends Mixins(tablePagination, loading, Resize) {
[x: string]: any;

    ysbChecked = false;

    queryForm = {
        deviceClassId: "",
        times: [],
        storeName: "",
        nickName: "",
        phone: "",
        status: ""
    };

    deviceCategoryOptions = [
    ];
    
    statusOptions = [
        { value: 1, label: "已评价" },
        { value: 2, label: "待评价" }
    ];

    itemTotal = 0;
    tableData = [];

    columns = [
        // 手机号、产品编码
        { label: "ID", prop: "id" },
        { label: "数据来源", prop: "sourceDesc" },
        { label: "设备分类", prop: "deviceClassName" },
        { label: "调理时间", prop: "createTime" },
        { label: "使用产品", prop: "productName" },
        { label: "用户名称", prop: "nickname" },
        { label: "手机号", prop: "phone" },
        { label: "服务评价", prop: "userScore" },
        { label: "商家回复", prop: "feedback" },
    ];

    visible = false
    pmsId = ""

    mounted() {
        this.windowResize(310);
        this.getCategoryOptions();
        this.getData();
    }

    handleSearch() {
        this.resetCurrent();
        this.getData();
    }

    ysbCheckedChange() {
        this.getData();
    }

    getCategoryOptions() {
        deviceClassTypeList(3).then((res) => {
            this.deviceCategoryOptions = res.data;
        })
    }

    getData() {
        this.showLoading();
        const { times } = this.queryForm;
        const params = {
            ...this.queryForm,
            ysb: this.ysbChecked,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            startTime: (times || [])[0] && dayjs((times || [])[0]).format("YYYY-MM-DD HH:mm:ss"),
            endTime: (times || [])[1] && dayjs((times || [])[1]).format("YYYY-MM-DD HH:mm:ss"),
        }
        userMemberRecuperateList(params).then(res => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        })
    }

    goUserList(row) {
        this.$router.push({
            path: "/userMember",
            query: { phone: row.phone }
        })
    }

    viewCommentDetail(row) {
        this.pmsId = row.id;
        this.visible = true;
    }
}
