
import { Watch, PropSync, Prop, Component, Vue } from "vue-property-decorator";
import { getCommentsByPms } from "@/api/userDetection";

@Component({
})
export default class UserRecuperateRecord extends Vue {
    @PropSync("visible") syncVisible !: boolean;
    @Prop() readonly pmsId !: string;

    commentDetail = {} as any

    cancel() {
        this.commentDetail = {};
        this.syncVisible = false;
    }
    @Watch("syncVisible")
    getComments(value) {
        console.log("getComments", this.pmsId, value)
        this.commentDetail = {};
        if (!this.pmsId || !value) return
        getCommentsByPms(this.pmsId).then((res) => {
            console.log("getCommentsByPms", res)
            this.commentDetail = res.data;
        })
    }

}

